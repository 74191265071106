<template>



  <nav v-if="!$route.meta.hideNavigation">
    <my-layout  >
      <router-view  />

    </my-layout>
 </nav>

 <nav v-else>
 <Intro_vable></Intro_vable>
 </nav>
</template>


<script>
import MyLayout from "@/components/layout/layout.vue";

  import Intro_vable from "@/views/Introvable"

export default {
  name: "App",
  components: {
    MyLayout,
    Intro_vable
  },

};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@100;300;400;700;900&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
.debug{
  border: 2px solid red;
  & > *{
    border: 2px solid green;
    & > *{
    border: 2px solid blue;
  }
  }
 
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter';
}

</style>
