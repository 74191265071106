import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap-icons/font/bootstrap-icons';
import VueApexCharts from "vue3-apexcharts";

import VueExcelXlsx from "vue-excel-xlsx";

createApp(App)
.use(store)
.use(router)
.use(BootstrapVue3)
.use(VueApexCharts)
.use(VueExcelXlsx)
.mount('#app')
 