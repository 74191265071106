import { createRouter, createWebHistory } from "vue-router";


const routes = [

  {
    path: "/about",
    name: "about",
    component: () => import("../views/about"),
  },

  
  {
    path: "/profile/:id",
    name: "profile",
    component: () => import("../views/Profil_info"),
  },

  {
    path: "/",
    name: "Dashboard",
    component: () => import("../views/Dashboard"),

  },
  {
    path: "/List",
    name: "List",
    component: () => import("../views/List"),
  },
  {

    path: "/Attendance",
    name: "Attendence",
    component: () => import("../views/Attendance"),

  },
  {
    path: "/Projects",
    name: "Projects",
    component: () => import("../views/Projects"),
  },
  {

    path: "/Clients",
    name: "Clients",
    component: () => import("../views/Client"),
  },
  {
    path: "/Absence",
    name: "Absence",
    component: () => import("../views/Absence"),
  },


 
  { 
    path: '/:pathMatch(.*)*', 
    component: () => import("../views/Introvable"), 
    meta: { hideNavigation: true }
   }

 
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export {routes}

export default router 
