
<template >

<div class="navbar"  ref="pdf" >

      
          <div id="listicone" class="toggle">
             
            <svg  @click="toggleSideNav" width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M18 17.6429C18 18.3924 17.4627 19 16.8 19H1.2C0.537281 19 0 18.3924 0 17.6429C0 16.8934 0.537281 16.2857 1.2 16.2857H16.8C17.4627 16.2857 18 16.8934 18 17.6429ZM1.2 10.8571H22.8C23.4627 10.8571 24 10.2495 24 9.5C24 8.7505 23.4627 8.14286 22.8 8.14286H1.2C0.537281 8.14286 0 8.7505 0 9.5C0 10.2495 0.537281 10.8571 1.2 10.8571ZM1.2 2.71429H12C12.6627 2.71429 13.2 2.10665 13.2 1.35715C13.2 0.607695 12.6627 0 12 0H1.2C0.537281 0 0 0.607695 0 1.35715C0 2.10665 0.537281 2.71429 1.2 2.71429Z" fill="#636E75"/>
            </svg>
            
          </div>



        <div  class="gauch_side">


                
                      <!-- <span id="icone_menu">
                        
                         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M2.35193 0C1.08821 0 0.0602417 1.02804 0.0602417 2.29168C0.0602417 3.55533 1.08779 4.5833 2.35193 4.5833C3.61607 4.5833 4.64361 3.55533 4.64361 2.29168C4.64361 1.02804 3.61557 0 2.35193 0Z" fill="#636E75"/>
                           <path d="M9.76916 0C8.50552 0 7.47748 1.02804 7.47748 2.29168C7.47748 3.55533 8.50552 4.5833 9.76916 4.5833C11.0329 4.5833 12.0611 3.55533 12.0611 2.29168C12.0611 1.02804 11.0333 0 9.76916 0Z" fill="#636E75"/>
                           <path d="M17.3061 4.58337C18.5695 4.58337 19.5978 3.5554 19.5978 2.29176C19.5978 1.02811 18.5704 0 17.3061 0C16.0418 0 15.0144 1.02804 15.0144 2.29168C15.0144 3.55533 16.0426 4.58337 17.3061 4.58337Z" fill="#636E75"/>
                           <path d="M2.29211 11.9265C3.55575 11.9265 4.58401 10.8988 4.58401 9.63492C4.58401 8.37099 3.55575 7.34302 2.29211 7.34302C1.02847 7.34302 0 8.37106 0 9.63492C0 10.8988 1.02847 11.9265 2.29211 11.9265Z" fill="#636E75"/>
                           <path d="M9.70887 11.9265C10.9723 11.9265 12.0006 10.8988 12.0006 9.63492C12.0006 8.37099 10.9731 7.34302 9.70887 7.34302C8.4448 7.34302 7.41718 8.37099 7.41718 9.63492C7.41718 10.8988 8.44522 11.9265 9.70887 11.9265Z" fill="#636E75"/>
                           <path d="M17.2467 11.9265C18.5106 11.9265 19.5384 10.8988 19.5384 9.63492C19.5384 8.37099 18.5106 7.34302 17.2467 7.34302C15.9828 7.34302 14.9542 8.37099 14.9542 9.63492C14.9542 10.8988 15.9824 11.9265 17.2467 11.9265Z" fill="#636E75"/>
                           <path d="M2.3446 14.6636C1.08096 14.6636 0.0524902 15.6913 0.0524902 16.9553C0.0524902 18.2187 1.08096 19.2469 2.3446 19.2469C3.60824 19.2469 4.63622 18.2192 4.63622 16.9553C4.63622 15.6913 3.60824 14.6636 2.3446 14.6636Z" fill="#636E75"/>
                           <path d="M9.76178 14.6636C8.49806 14.6636 7.46967 15.6913 7.46967 16.9553C7.46967 18.2187 8.49771 19.2469 9.76178 19.2469C11.0252 19.2469 12.0534 18.2192 12.0534 16.9553C12.0534 15.6913 11.0252 14.6636 9.76178 14.6636Z" fill="#636E75"/>
                           <path d="M17.299 14.6636C16.0356 14.6636 15.0073 15.6913 15.0073 16.9553C15.0073 18.2187 16.0356 19.2469 17.299 19.2469C18.5629 19.2469 19.5907 18.2192 19.5907 16.9553C19.5907 15.6913 18.5624 14.6636 17.299 14.6636Z" fill="#636E75"/>
                         </svg>
                      </span>  -->


                       <button>
                      <div id="icone-notification " class="notification">
                          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.3174 23C12.0531 23 13.505 21.7627 13.8384 20.125H6.79651C7.13009 21.7627 8.58198 23 10.3174 23Z" fill="#636E75"/>
                          <path d="M17.0371 11.4991C17.0333 11.4991 17.0294 11.5 17.0255 11.5C13.3274 11.5 10.3173 8.49078 10.3173 4.79175C10.3173 3.77486 10.5512 2.81361 10.9585 1.94921C10.7475 1.92921 10.5338 1.91675 10.3173 1.91675C6.61244 1.91675 3.609 4.92002 3.609 8.62502V11.2968C3.609 13.1934 2.77812 14.9836 1.32044 16.2159C0.834543 16.6309 0.618883 17.2921 0.793306 17.9372C0.996507 18.6875 1.73824 19.1667 2.51648 19.1667H18.1133C18.9298 19.1667 19.6994 18.6377 19.8651 17.8375C19.9935 17.2184 19.7731 16.6002 19.293 16.1968C17.8996 15.0287 17.0936 13.3122 17.0371 11.4991Z" fill="#636E75"/>
                          <path d="M21.8173 4.79173C21.8173 7.43808 19.6721 9.58328 17.0256 9.58328C14.3792 9.58328 12.234 7.43808 12.234 4.79173C12.234 2.14537 14.3792 0 17.0256 0C19.6721 0 21.8173 2.14537 21.8173 4.79173Z" fill="#636E75"/>
                          </svg>
                      </div>
                    </button>
             
                      <button>

              <div class="profilee "  @click="generatePDF()">

               
                <span>
                   <img id = "image" src="@/assets/image/Yassine..png"  alt="photo de profil">
                </span>
                 
                <span >
                   <p>Yassine Ouarrak</p>
                </span>
       
                <span >
                  <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.64018 0.231779C1.21591 -0.121785 0.585343 -0.0644614 0.231779 0.359816C-0.121785 0.784093 -0.0644614 1.41466 0.359816 1.76822L1.64018 0.231779ZM7 6L6.35982 6.76822C6.73066 7.07726 7.26934 7.07726 7.64018 6.76822L7 6ZM13.6402 1.76822C14.0645 1.41466 14.1218 0.784093 13.7682 0.359816C13.4147 -0.0644614 12.7841 -0.121785 12.3598 0.231779L13.6402 1.76822ZM0.359816 1.76822L6.35982 6.76822L7.64018 5.23178L1.64018 0.231779L0.359816 1.76822ZM7.64018 6.76822L13.6402 1.76822L12.3598 0.231779L6.35982 5.23178L7.64018 6.76822Z" fill="#737898"/>
                  </svg>
                </span>
             
              </div>
            </button>
            </div>
</div>


</template>



<script>


import html2pdf from "html2pdf.js"
export default {
    name : 'nav-bar',
    components:{},
    data() {
      return {
        
      }
    },

    methods: {
    toggleSideNav() {
      this.$emit("toggleSideNav");
    },

    generatePDF() {
			const doc = this.$refs.pdf
			html2pdf(doc)
		},

  },
} 


</script>



<style lang="scss" >




.navbar{
  height: 70px;
  width: 100%;
  top: 0px;
  box-sizing: border-box;
  padding: 4rem;
  display: flex;
  justify-content:space-between;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);  
  align-items: center;
  svg:hover{
  path{
    fill: black;
  }
}
}



.gauch_side{

  margin-right: 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;

}

.profilee{

  display: flex;
  align-items:center;
  gap: 1.5em;
  justify-content: space-between;

  padding: 5px;
}
.profilee:hover{
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.035);
  height: 50px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); 


  
  }
#image{
  width: 41px;
  height: 41px;
  border-radius: 100px;
}
p{
display: inline;  
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;
font-feature-settings: 'salt' on, 'liga' off;
}

#listicone{
  margin-left: 1rem;
  cursor: pointer;
}
button{
  border: none;
  background-color: #ffffff;
}
</style>