<template lang="">
    <div class="layout">
      <my-side-nav :open="open"></my-side-nav>
  
      <div class="main">
        <my-nav @toggleSideNav="open = !open  "></my-nav>
        <slot></slot>
      </div>
    </div>
  </template>
  <script>
  
  import MyNav from "@/components/layout/nav-bar.vue";
  import MySideNav from "@/components/layout/Sid-bar.vue";
  export default {
    name: "MyLayout",
    components: {
      MyNav,
      MySideNav,
    },
    data() {
      return {
        open: true,

      };
    },
    
  };
  </script>

  <style scoped>

  .layout {
    padding: 0;
    margin: 0;
    display: flex;
    height: 100vh;
   
  }
  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: calc(100vh);
    background-color: #F6F9FF;
  }
  </style>